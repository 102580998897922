<template>
  <div class="home">
    <meet
      v-if="isJoinMeeting"
      :channel="channel"
      :appid="appid"
      :token="token"
      :preMute="config.mute"
      :preCameraOff="config.cameraOff"
      @leave-meeting="handleLeaveMeeting"
    />
    <!-- <div v-else>
      <div class="join-button" @click="handleJoin">进入会议</div>
    </div> -->
    <main-page
      v-else
      @join-meeting="handleJoinMeeting"
      :channel="channel"
      :appid="appid"
      :token="token"
    />
  </div>
</template>

<script>
import Meet from "@/components/Meet.vue";
import MainPage from "@/components/Main.vue";

export default {
  name: "Home",
  components: {
    Meet,
    MainPage
  },
  data() {
    return {
      isJoinMeeting: false,
      config: null,
      appid: 'c88855b3fcce4150aaa36630e835bf49',
      channel: 'testChannel',
      token:
        '007eJxTYLjeoXSvds+WJcXsNTNuhKRkLqtobfj04l4/n6rcfjOvF7oKDEZJpmZpaQYWFgbGaSYmZqmJKUmmJilmKQaWBhbJhuaWvqc40xsCGRkcQmMZGKEQxOdmKEktLnHOSMzLS81hYAAAcusiBg=='
    };
  },
  // watch: {
  //   appid: {
  //     immediate: true,
  //     handler(newV) {
  //       if (!newV || newV === "null" || newV === undefined) {
  //         const appid = window.prompt("Input your appid:");
  //         if (appid) {
  //           localStorage.setItem("appid", appid);
  //           this.appid = appid;
  //         } else {
  //           window.location.reload();
  //         }
  //       }
  //     }
  //   },
  //   channel: {
  //     immediate: true,
  //     handler(newV) {
  //       if (!newV || newV === "null" || newV === undefined) {
  //         const channel = window.prompt("Input your room channel:");
  //         if (channel) {
  //           sessionStorage.setItem("channel", channel);
  //           this.channel = channel;
  //         } else {
  //           window.location.reload();
  //         }
  //       }
  //     }
  //   },
  //   token: {
  //     immediate: true,
  //     handler(newV) {
  //       if (!newV && newV !== null) {
  //         const token = window.prompt("Input your token:");
  //         if (token) {
  //           localStorage.setItem("token", token);
  //           this.token = token === "null" ? null : token === null ? undefined : token;
  //         } else {
  //           window.location.reload();
  //         }
  //       }
  //     }
  //   }
  // },
  methods: {
    handleJoin(){
      this.handleJoinMeeting({
        channel: this.channel,
        mute: false,
        cameraOff: false
      })
    },
    handleJoinMeeting(config) {
      console.log('config',config)
      // cameraOff: false,
      // channel: "testChannel",
      // mute: false
      this.isJoinMeeting = true;
      this.config = config;
    },
    handleLeaveMeeting() {
      this.isJoinMeeting = false;
    }
  }
};
</script>
