<template>
  <div class="meet">
    <div class="meet_time flex">
      <div class="time_left flex">
        <img v-if="inMeeting" @click="handleLeave" :src="closeImg" mode="" />
        <img :src="statusImg" mode="" />
        <span>{{ meetTime }}</span>
      </div>
      <div class="time_right">
        <span>患者信息</span>
      </div>
    </div>
    <div class="local_player">
      <agora ref="ar" :channel="channel" :appid="appid" :token="token" :autoStart="true" :errorHandler="handleError"
        @user-joined="handleUserJoin" @user-published="handleUserPublished" @user-left="handleUserLeft"
        @join-success="handleJoinSuccess" @client-created="handleClientCreated" @stream-fallback="handleStreamFallback"
        enableDualStream>
        <agora-audio-sender :mute="mute" @track-created="handleAudioTrackCreated"
          ref="audioSender"></agora-audio-sender>
        <agora-audio-receiver ref="audioReceiver" :refuse="refuseList" />
        <agora-video-sender :cameraOff="cameraIsClosed" customizationPlayer ref="videoSender"
          @video-ready="handleVideoReady" @video-close="handleVideoClose"></agora-video-sender>
        <agora-video-receiver customizationPlayer @video-ready="handleRemoteVideoReady"
          :refuse="refuseList"></agora-video-receiver>
      </agora>
      <agora v-if="openScreenSharing" :channel="channel" :appid="appid" :token="token" :uid="shareScreenUID"
        ref="screenAr">
        <agora-video-sender customizationPlayer type="screen" @video-ready="handleScreenVideoReady"
          @video-close="handleScreenVideoClose" @video-create-failed="handleScreenVideoFailed"></agora-video-sender>
      </agora>
    </div>
    <div>
      
    </div>
    <div class="player flex" :class="{'screen-share-player': youAreShareScreening || otherIsShareScreening}">
      <div class="user-vision" :class="{'screen-share-vision': user_id === shareScreenUID,'screen-share-vision-pined':user_id === shareScreenUID && user_id === pinedUid, 'single':userIdList.length == 1}" v-for="user_id in userIdList" :key="user_id">
        <div v-if="playList.find(e => e.uid === user_id)" v-player="playList.find(e => e.uid === user_id)" class="player-vision" v-show="!streamFallbackList.includes(user_id)"></div>
        <div class="status flex">
          <div class="circle red"></div>
          <p> {{ user_id || "you" }}
          <span v-if="user_id === uid && inMeeting"> ( 我 ) </span>
          </p>
        </div>
        <div class="ban flex">
          <pin-button :couldHover="false" v-if="pined && user_id === pinedUid" />
          <div class="icon audio flex">
            <img :src="audioImg" mode="" />
            <div class="line" v-if="mute"></div>
          </div>
          <div class="icon video flex">
            <img :src="videoImg" mode="" />
            <div class="line" v-if="cameraIsClosed"></div>
          </div>
          <voice-dot v-if="!mute" :level="audioStatusObj[user_id || uid] && audioStatusObj[user_id || uid].level ? audioStatusObj[user_id || uid].level : 0" :mute="audioStatusObj[user_id || uid] && audioStatusObj[user_id || uid].mute !== false"/>
        </div>
        <avatar-audio avatar="../assets/yonghu.svg" :level="audioStatusObj[user_id || uid] && audioStatusObj[user_id || uid].level ? audioStatusObj[user_id || uid].level : 0" :mute="audioStatusObj[user_id || uid] && audioStatusObj[user_id || uid].mute !== false" :cameraOff="!playList.find(e => e.uid === user_id) || streamFallbackList.includes(user_id)" />
        <div class="central">
          <pin-button class="pin-button-local" :pined="pined && (user_id ? pinedUid === user_id : pinedUid === uid)" @click="handlePinUser(user_id || uid)" />
        </div>
      </div>
    </div>
    <div class="meet_opera flex">
      <div class="box flex" @click="handleMute">
        <div class="icon flex">
          <img :src="audioImg" mode="" />
          <div class="line" v-if="mute"></div>
        </div>
        <span>{{ mute ? '解除静音' : '静音' }}</span>
      </div>
      <div class="box flex" @click="handleCamera">
        <div class="icon flex">
          <img :src="videoImg" mode="" />
          <div class="line" v-if="cameraIsClosed"></div>
        </div>
        <span>{{ cameraIsClosed ? '开启视频' : '关闭视频' }}</span>
      </div>
      <div class="box flex" @click="handleShareScreen">
        <div class="icon flex">
          <img :src="shareImg" mode="" />
        </div>
        <!-- {{ '共享屏幕':'停止共享' }} -->
        <span>{{ openScreenSharing?'停止共享':'共享屏幕' }}</span>
      </div>
      <div class="box flex" @click="handleExpandUserList">
        <div class="icon flex">
          <img :src="peopleImg" mode="" />
        </div>
        <span>管理成员({{ users.length }})</span>
      </div>
      <div class="box flex" @click="showMorePop = true">
        <div class="icon flex">
          <img :src="moreImg" mode="" />
        </div>
        <span>更多</span>
      </div>
    </div>
    <!-- people -->
    <div class="user-list" v-show="showExpandUserList">
      <div class="title flex">
        <span @click="handleCustom">入会成员 :</span>
        <div class="button flex" @click="handleAllMute">{{ allMute?'解除全体静音':'全体静音' }}</div>
      </div>
      <ul>
        <li class="flex" v-for="(item, index) in users" :key="index">
          <!-- <voice-dot class="audio-dot-local" :level="audioStatusObj[item.uid || uid] && audioStatusObj[item.uid || uid].level ? audioStatusObj[item.uid || uid].level : 0" :mute="audioStatusObj[item.uid || uid] && audioStatusObj[item.uid || uid].mute !== false"/> -->
          <span class="user">{{ item.uid || item }}</span>
          <!-- 禁言 -->
          <div class="icon audio flex">
            <img :src="audioImg" mode="" />
            <div class="line" v-if="item.mute || false"></div>
          </div>
          <!-- 视频 -->
          <div class="icon video flex">
            <img :src="videoImg" mode="" />
            <div class="line" v-if="item.cameraIsClosed || false"></div>
          </div>
          <!-- 踢出 -->
          <span>踢出</span>
          <!-- 切换画面 -->
          <pin-button class="pin-button-local" :pined="pined && (item.uid ? pinedUid === item.uid : pinedUid === uid)
            " @click="handlePinUser(item.uid || uid)" />
        </li>
      </ul>
    </div>
    <!-- more -->
    <div class="more flex" v-show="showMorePop">
      <div class="mask" @click="showMorePop = false"></div>
      <div class="more_content">
        <ul class="flex">
          <li class="flex" v-for="(item, index) in moreList" :key="index" @click="handleMore(item.key)">
            <div class="icon flex">
              <img :src="item.icon" mode="" />
            </div>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MpButton from "./buttons/mp-button";
import CloseButton from "./buttons/close-button";
import OnCallButton from "./buttons/on-call-button";
import VideoButton from "./buttons/video-button";
import VoiceDot from "./voice-dot/main";
import AvatarAudio from "./avatar-audio/main";
import PinButton from "./pin-button/main";
import audioImg from "@/assets/audio.png";
import videoImg from "@/assets/video.png";
import closeImg from "@/assets/close.png";
import statusImg from "@/assets/status.png";
import shareImg from "@/assets/share.png";
import peopleImg from "@/assets/people.png";
import moreImg from "@/assets/more.png";
import emceeImg from "@/assets/emcee.png";
import signImg from "@/assets/sign.png";
import opinionImg from "@/assets/opinion.png";
import handImg from "@/assets/hand.png";
export default {
  name: "Meet",
  components: {
    MpButton,
    CloseButton,
    OnCallButton,
    VideoButton,
    VoiceDot,
    AvatarAudio,
    PinButton
  },
  props: {
    channel: {
      type: [String, null]
    },
    appid: {
      type: [String, null]
    },
    token: {
      type: [String, null]
    },
    preMute: {
      type: Boolean,
      default: false
    },
    preCameraOff: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mute: false,
      audioImg: audioImg,
      videoImg: videoImg,
      closeImg: closeImg,
      statusImg: statusImg,
      shareImg: shareImg,
      peopleImg: peopleImg,
      moreImg: moreImg,
      emceeImg: emceeImg,
      signImg: signImg,
      opinionImg: opinionImg,
      handImg: handImg,
      seconds: 0,
      timer: null,
      allMute: false,
      showMorePop: false,
      moreList: [{
        icon: emceeImg,
        key: 'emcee',
        name: "只看主持人",
      },{
        icon: signImg,
        key: 'sign',
        name: "签到",
      },{
        icon: opinionImg,
        key: 'opinion',
        name: "会诊意见书",
      },{
        icon: handImg,
        key: 'hand',
        name: "举手发言",
      }],
      handleError: error => {
        this.$message.error(error.message || error);
      },
      uid: null,
      cameraIsClosed: false,
      inMeeting: false,
      remoteUsers: [],
      showExpandUserList: false,
      localDirective: null,
      localScreenDirective: null,
      localVolumeLevel: 0,
      remoteVolumeLevelList: [],
      remoteDirectiveList: [],
      remoteMuteStatus: [],
      pined: false,
      pinedUid: null,
      openScreenSharing: false,
      youAreShareScreening: false,
      shareScreenUID: 99991234,
      streamFallbackList: []
    };
  },
  computed: {
    refuseList() {
      return this.openScreenSharing ? [this.shareScreenUID] : [];
    },
    microphoneClass() {
      return this.mute ? "mp-mute" : "mp-normal";
    },
    cameraClass() {
      return this.cameraIsClosed ? "video-mute" : "video-normal";
    },
    users() {
      let result = [...this.remoteUsers];
      result.unshift(this.uid ? this.uid + "(you)" : "you");
      this.youAreShareScreening && result.unshift(this.shareScreenUID);
      return result;
    },
    userList() {
      let result = [...this.remoteUsers];
      this.uid && result.unshift(this.uid);
      this.youAreShareScreening && result.unshift(this.shareScreenUID);
      return result;
    },
    unpinedUserIdList() {
      let result = [...this.remoteUsers.map(user => user.uid)];
      this.uid && result.unshift(this.uid);
      this.youAreShareScreening && result.unshift(this.shareScreenUID);
      return result;
    },
    userIdList() {
      if (this.pined) {
        return [this.pinedUid];
      }
      return this.unpinedUserIdList;
    },
    playList() {
      let result = [...this.remoteDirectiveList];
      this.localDirective && result.unshift(this.localDirective);
      this.localScreenDirective && result.unshift(this.localScreenDirective);
      return result;
    },
    remoteAudioStatusObj() {
      const volumeLevel = Object.assign(
        {},
        ...this.remoteVolumeLevelList.map(e => ({ [e.uid]: e.result }))
      );
      const muteStatus = Object.assign(
        {},
        ...this.remoteMuteStatus.map(e => ({ [e.uid]: e.result }))
      );
      return Object.assign(
        {},
        ...this.remoteUsers
          .map(user => user.uid)
          .map(k => ({
            [k]: {
              level: volumeLevel[k] || 0,
              mute: muteStatus[k] !== false
            }
          }))
      );
    },
    audioStatusObj() {
      return this.localVolumeLevel || this.localVolumeLevel === 0
        ? {
          ...{
            [this.uid]: { level: this.localVolumeLevel, mute: this.mute }
          },
          ...this.remoteAudioStatusObj
        }
        : {
          ...this.remoteAudioStatusObj
        };
    },
    otherIsShareScreening() {
      return (
        !this.youAreShareScreening &&
        this.unpinedUserIdList.includes(this.shareScreenUID)
      );
    },
    meetTime() {
      const hours = Math.floor(this.seconds / 3600);
      const minutes = Math.floor((this.seconds % 3600) / 60);
      const seconds = this.seconds % 60;
      return `${this.formatTime(hours)}:${this.formatTime(minutes)}:${this.formatTime(seconds)}` || "00:00:00";
    }
  },
  watch: {
    cameraIsClosed(newV) {
      if (!newV && this.$refs.videoSender && this.$refs.localCameraPlayer) {
        this.playLocalVideoOnTopBanner();
      }
    }
  },
  created() {
    this.mute = this.preMute;
    this.cameraIsClosed = this.preCameraOff;
    this.timer = setInterval(() => {
      this.seconds++;
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    handleOpenNewPage() {
      window.open(window.location.href);
    },
    handleCustom() {
      const tracks = this.$refs.ar.getLocalTracks();
      console.log(tracks);
    },
    handleJoinSuccess(uid) {
      this.inMeeting = true;
      this.uid = uid;
      this.$message.success("Join the meeting successfully");
    },
    handleClientCreated() {
      window._agMeet = this;
      window._client = this.$refs.ar.getClient();
      window._AgoraRTC = this.$refs.ar.getAgoraRtc();
      window._sClient = this.$refs?.screenAr?.getClient();
      // client.setStreamFallbackOption()
    },
    handleMute() {
      this.mute = !this.mute;
      this.$message(`Microphone Turned ${this.mute ? "OFF" : "ON"}`);
    },
    playLocalVideoOnTopBanner() {
      const videoTrack = this.$refs.videoSender
        .getTrack()
        .getMediaStreamTrack();
      this.$refs.localCameraPlayer.srcObject = new MediaStream([videoTrack]);
    },
    handleCall() {
      if (this.inMeeting) {
        this.$message.error("You are already in the meeting");
        return;
      }
      this.$refs.ar.start().then(({ result, message }) => {
        if (!result) {
          this.$message.error("join channel error", message);
        }
      });
    },
    handleLeave() {
      if (!this.inMeeting) {
        this.$message.error("You have not joined any meetings");
        return;
      }
      this.$refs.ar.leave().then(() => {
        this.inMeeting = false;
        this.remoteUsers = [];
        this.uid = null;
        this.$message.success("Left the meeting successfully");
        this.$emit("leave-meeting");
      });
    },
    handleCamera() {
      this.cameraIsClosed = !this.cameraIsClosed;
      this.$message(`Camera Turned ${this.cameraIsClosed ? "OFF" : "ON"}`);
    },
    handleUserJoin(user) {
      this.$message(`${user.uid} join meeting`);

      // weak net fallback
      this.$refs.ar.getClient().setStreamFallbackOption(user.uid, 2);

      // if you are sharing your screen,
      // you need not subscribe the screen video of yourself.
      this.remoteUsers = this.$refs.ar
        .getRemoteUsers()
        .filter(
          user => !this.openScreenSharing || user.uid !== this.shareScreenUID
        );
      this.handleCheckRemoteUserAudioMuteStatus();
    },
    handleUserPublished(user, mediaType) {
      console.log("user published ", mediaType, user.uid);

      if (mediaType === "audio") {
        this.handleGetRemoteVolumeLevelList();
      }
    },
    handleUserLeft(user, reason) {
      this.$message(`${user.uid} left meeting because ${reason}`);
      this.remoteUsers = this.$refs.ar
        .getRemoteUsers()
        .filter(
          user => !this.openScreenSharing || user.uid !== this.shareScreenUID
        );

      if (user.uid === this.pinedUid) {
        this.pined = false;
        this.pinedUid = null;
      }
    },
    handleExpandUserList() {
      this.showMorePop = false;
      this.showExpandUserList = !this.showExpandUserList;
    },
    handleVideoReady(localVideo, info) {
      console.log("video-ready trigger:", info);
      this.localDirective = localVideo;
      this.playLocalVideoOnTopBanner();
    },
    handleScreenVideoReady(screenVideo) {
      this.youAreShareScreening = true;
      this.localScreenDirective = screenVideo;
    },
    handleVideoClose() {
      this.localDirective = null;
    },
    handleScreenVideoClose() {
      this.youAreShareScreening = false;
      this.localScreenDirective = null;
    },
    handleScreenVideoFailed() {
      this.handleScreenVideoClose();
      this.openScreenSharing = false;
    },
    handleRemoteVideoReady(remoteUserList) {
      this.remoteDirectiveList = remoteUserList;
    },
    handleAudioTrackCreated() {
      let id = undefined;
      const audioSender = this.$refs.audioSender;
      const callback = () => {
        try {
          const track = audioSender.getTrack();
          if (track && track.getVolumeLevel) {
            this.localVolumeLevel = track.getVolumeLevel();
          } else {
            this.localVolumeLevel = 0;
          }
          id = window.requestAnimationFrame(callback);
        } catch (e) {
          console.error(e);
        }
      };
      id = window.requestAnimationFrame(callback);
      this.$once("hook:beforeDestroy", () => {
        window.cancelAnimationFrame(id);
      });
    },
    handleGetRemoteVolumeLevelList() {
      let id = undefined;
      const audioReceiver = this?.$refs?.audioReceiver;
      const callback = () => {
        this.remoteVolumeLevelList = audioReceiver?.getVolumeLevel();
        id = window.requestAnimationFrame(callback);
      };
      id = window.requestAnimationFrame(callback);
      this.$once("hook:beforeDestroy", () => {
        window.cancelAnimationFrame(id);
      });
    },
    handleCheckRemoteUserAudioMuteStatus() {
      let id = undefined;
      const audioReceiver = this?.$refs?.audioReceiver;
      const callback = () => {
        this.remoteMuteStatus = audioReceiver?.getUserMuteStatus();
        id = window.requestAnimationFrame(callback);
      };
      id = window.requestAnimationFrame(callback);
      this.$once("hook:beforeDestroy", () => {
        window.cancelAnimationFrame(id);
      });
    },
    handlePinUser(uid) {
      if (this.pined && this.pinedUid === uid) {
        this.pined = false;
        this.pinedUid = null;
      } else {
        this.pinedUid = uid;
        this.pined = true;
      }
    },
    handleShareScreen() {
      if (!this.youAreShareScreening) {
        if (this.otherIsShareScreening) {
          this.$message.warning(`other is sharing, and you will replace him.`);
        }
      } else {
        this.$message(`you will quit screen sharing.`);
      }
      this.openScreenSharing = !this.openScreenSharing;
    },
    handleStreamFallback(uid, type) {
      const list = this.streamFallbackList;
      console.log(`[meeting] : stream fallback: uid ${uid}, type: ${type}`)
      if (type === "recover") {
        this.streamFallbackList = list.filter(e => e !== uid);
      } else if (type === "fallback") {
        this.streamFallbackList = [...new Set([...list, uid])];
      } else {
        this.$message.error("stream fallback type error");
      }
    },
    formatTime(num) {
      // 如果数字小于 10，则前面补零
      return num < 10 ? '0' + num : num.toString();
    },
    handleAllMute(){
      this.allMute = !this.allMute
    },
    handleMore(key){
      this.showExpandUserList = false;
      this.showMorePop = !this.showMorePop;
      if(key == 'emcee'){
        return
      }
      if(key == 'sign'){
        return
      }
      if(key == 'opinion'){
        return
      }
      if(key == 'hand'){
        return
      }
    },
  }
};
</script>

<style lang="stylus">
video.agora_video_player
  // object-fit: contain !important
</style>

<style scoped lang="stylus">
@import "../styles/meet/index.styl"
</style>